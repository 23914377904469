import React from 'react';
import { Container, Typography} from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo'

const dmcaPolicy = () => (
    <Layout location="">
        <SEO description='Blix DMCA Policy' title='DMCA Policy'/> 
        <Container fixed style={{paddingTop: '150px', paddingBottom: '50px'}}>
            <Typography color='textPrimary'>DMCA Policy</Typography>
            <Typography color='textSecondary'>
            <div id='privacy2'>
                <p>
                We take the intellectual property rights of others seriously and require that our customers and users do the same. The Digital Millennium Copyright Act established a process for addressing claims of copyright infringement that we have implemented for Blix Services. If you own a copyright or have authority to act on behalf of a copyright owner and want to report a claim that a third party is infringing that material on or through a Blix service, please send a notice to our copyright agent that includes all of the items below and we will expeditiously take appropriate action:
                </p>
                <p>
                A description of the copyrighted work that you claim is being infringed.
                </p>
                <p>
                A description of the material you claim is infringing and that you want removed or access to which you want disabled and the URL or other location of that material.
                </p>
                <p>
                Your address, telephone number, and email address.
                </p>
                <p>
                The following statement: “I have a good faith belief that the use of the copyrighted material I am complaining of is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)”.
                </p>
                <p>
                The following statement: “The information in this notice is accurate and, under penalty of perjury, I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right that is allegedly infringed”.
                </p>
                <p>
                An electronic or physical signature of the owner of the copyright or a person authorized to act on the owner’s behalf.
                </p>
                <p>
                Our designated copyright agent to receive such claims can be reached as follows: <a style={{color: '#1AA3FF'}} href="mailto:support@blix.net">support@blix.net</a>
                </p>
                <p>
                We may, in appropriate circumstances, disable or terminate the accounts of users who may be repeat infringers.
                </p>
                <p>
                This process does not limit our ability to pursue any other remedies we may have to address suspected infringement.
                </p>
            </div>
        </Typography>
    </Container>
    </Layout>
);

export default dmcaPolicy;